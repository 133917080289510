<template>
    <DocenteLogros></DocenteLogros>
</template>
<script>
import DocenteLogros from "@/components/DocenteLogros.vue"
export default {
    components:{
        DocenteLogros,

    }
}
</script>