<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
          @change="obtAsignaturas"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Grupo"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Asignatura"
          item-text="nombre"
          item-value="id"
          :items="asignaturas"
          v-model="asignaturaId"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="mallaFiltro"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.logros`]="{ item }">
            <v-textarea
              v-model="item.logro"
              no-resize
              rows="1"
              @change="actualizarLogro(item)"
            >
              <template v-slot:label>
                <div>Descripción logro</div>
              </template>
            </v-textarea>
          </template>
          <template v-slot:[`item.logroPiar`]="{ item }">
            <v-textarea
              v-model="item.logroPiar"
              no-resize
              rows="1"
              @change="actualizarLogro(item)"
            >
              <template v-slot:label>
                <div>Descripción logro Piar</div>
              </template>
            </v-textarea>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    malla: [],
    periodoId: 0,
    grupoId: 0,
    asignaturaId: 0,
    periodos: [],
    grupos: [],
    asignaturas: [],
    headers: [
      { text: "Asignatura", value: "nombreAsignatura" },
      { text: "Grupo", value: "nombreGrupo" },
      { text: "Logros", value: "logros", sortable: false },
      { text: "Logros Piar", value: "logroPiar", sortable: false },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    mallaFiltro() {
      if (this.grupoId !== 0 || this.asignaturaId !== 0) {
        if (this.grupoId !== 0 && this.asignaturaId !== 0) {
          return this.malla.filter((x) => {
            return (
              x.idAsignatura === this.asignaturaId && x.grupoId === this.grupoId
            );
          });
        } else {
          return this.malla.filter((x) => {
            return (
              x.idAsignatura === this.asignaturaId || x.grupoId === this.grupoId
            );
          });
        }
      } else {
        return this.malla;
      }
    },
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetLogros",
      "UpdateLogro",
      "ModificarMensaje",
    ]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    obtAsignaturas() {
      this.GetLogros(this.periodoId).then((x) => {
        this.malla = x.data;
        this.grupos = this.obetnerFiltros(
          this.malla,
          "grupoId",
          "nombreGrupo",
          "Todos",
          0
        );

        this.asignaturas = this.obetnerFiltros(
          this.malla,
          "idAsignatura",
          "nombreAsignatura",
          "Todas",
          0
        );
      });
    },
    actualizarLogro(item) {
      debugger
      let mallaPeriodo = {};
      mallaPeriodo.id = item.id;
      mallaPeriodo.logro = item.logro;
      mallaPeriodo.logroPiar = item.logroPiar;
      mallaPeriodo.periodoId = item.periodoId;
      mallaPeriodo.docenteId = item.docenteId;
      mallaPeriodo.mallaId = item.mallaId;
      mallaPeriodo.grupoId = item.grupoId;
      this.UpdateLogro(mallaPeriodo).then((x) => {
        this.ModificarMensaje({ texto: "Registro Exitoso!" });
      });
    },
    obetnerFiltros(datos, id, nombre, textoDefault, idDefault) {
      const filteredArr = datos.reduce((acc, current) => {
        let dato = {
          id: current[id],
          nombre: current[nombre],
        };

        const x = acc.find((item) => item.id === dato.id);
        if (!x) {
          return acc.concat([dato]);
        } else {
          return acc;
        }
      }, []);
      var merged = [].concat.apply(
        [{ id: idDefault, nombre: textoDefault }],
        filteredArr
      );

      return merged;
    },
  },
};
</script>
